import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

import {ScrollUpDirective} from '@shared/directives/scroll-up/scroll-up.directive';
import {ScrollUpComponent} from '@shared/directives/scroll-up/scroll-up.component';

@NgModule({
	declarations: [ScrollUpDirective, ScrollUpComponent],
	exports: [ScrollUpDirective],
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
	]
})
export class ScrollUpModule {}
