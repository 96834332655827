import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {NgxSelectModule} from 'ngx-select-ex';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NgxBootstrapModule} from './ngx-bootstrap.module';
import {FullCalendarModule} from '@fullcalendar/angular';
import {NgxGraphModule} from '@swimlane/ngx-graph';
import {AngularSplitModule} from 'angular-split';
import {PieCanvasModule} from '@shared/components/charts/modules/pie-canvas/pie-canvas.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TimepickerModule.forRoot(),
		BsDatepickerModule.forRoot(),
		TabsModule.forRoot(),
		ProgressbarModule.forRoot(),
		RouterModule,
		NgxSelectModule,
		NgxBootstrapModule,
		FullCalendarModule,
		NgxGraphModule,
		AngularSplitModule,
		PieCanvasModule,
	],
	declarations: [],
	exports: [
		TimepickerModule,
		BsDatepickerModule,
		TabsModule,
		FormsModule,
		ReactiveFormsModule,
		ProgressbarModule,
		NgxUiLoaderModule,
		NgxUiLoaderModule,
		RouterModule,
		NgxSelectModule,
		NgxBootstrapModule,
	],
})
export class SharedModule {
}
