import {NgModule} from '@angular/core';
import {ScrollComponent} from '@shared/ui-components/scroll/scroll.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ScrollToTopModule} from '@shared/ui-components/scroll-to-top/scroll-to-top.module';
import {ScrollUpModule} from '@shared/directives/scroll-up/scroll-up.module';
import {ScrollItemDirective} from '@shared/ui-components/scroll/directives/scroll.directive';
import {CommonModule} from '@angular/common';

@NgModule({
	declarations: [ScrollComponent, ScrollItemDirective],
	imports: [ScrollingModule, ScrollToTopModule, ScrollUpModule, CommonModule],
	exports: [ScrollComponent, ScrollItemDirective],
})
export class ScrollModule {
}
