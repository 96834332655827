import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {AccordionModule} from 'ngx-bootstrap/accordion';

@NgModule({
	imports: [
		CommonModule,
		TimepickerModule.forRoot(),
		BsDatepickerModule.forRoot(),
		TabsModule.forRoot(),
		ProgressbarModule.forRoot(),
		PaginationModule.forRoot(),
		PopoverModule.forRoot(),
		CollapseModule.forRoot(),
		BsDropdownModule.forRoot(),
		CarouselModule.forRoot(),
		AccordionModule.forRoot(),
	],
	exports: [
		TimepickerModule,
		BsDatepickerModule,
		TabsModule,
		ProgressbarModule,
		PaginationModule,
		PopoverModule,
		CollapseModule,
		BsDropdownModule,
		CarouselModule,
		AccordionModule,
	]
})
export class NgxBootstrapModule {
}
