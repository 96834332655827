import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {EventBusService} from '@shared/utils/event-bus';
import {nextTick, sleep, time} from '@shared/utils';
import {IChartPoint} from '@shared/components/charts/modules/pie-canvas/models/pie-canvas.types';

declare var CanvasJS: any;

@Component({
	selector: 'app-pie-canvas',
	templateUrl: './pie-canvas.component.html',
	styleUrls: ['./pie-canvas.component.scss'],
})
export class PieCanvasComponent implements OnChanges, OnInit, OnDestroy {
	@Input() pieName;
	@Input() dataPoints: IChartPoint[];
	@Input() titleText;
	@Input() subtitlesText;
	@Input() useMinWidth = true;

	// tslint:disable-next-line:no-output-on-prefix
	@Output() onSelect = new EventEmitter<any>();

	@Input() isLoading = true;
	@Output() endLoading = new EventEmitter<void>();

	@Output() canvasData = new EventEmitter<any>();

	public chart;

	public isVisibleLegend: boolean;
	public idScrollOfLegend: string;

	constructor(
		private _eventBus: EventBusService,
	) {
		this.isVisibleLegend = false;
		this.idScrollOfLegend = 'scroll-pie-canvas';
	}

	@HostListener('window:resize', ['$event'])
	public checkAccess() {
		this.chart.render();
	}

	async ngOnInit() {
		await sleep(200);
		this.buildPie();

		await this.chartRender();

		this.endLoading.emit();
		this.isLoading = false;
		// @ts-ignore
		const canvas = document.getElementsByClassName('canvasjs-chart-canvas') as HTMLCanvasElement;
		this.canvasData.emit(canvas);
	}

	public ngOnDestroy(): void {
		this.chart?.destroy();
		this.chart = undefined;
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.dataPoints?.currentValue && this.chart) {
			this.sortDataPoints();

			this.chart.options.data[0].dataPoints = this.dataPoints;

			nextTick().then(() => this.chart.render());
			// @ts-ignore
			const canvas = document.getElementsByClassName('canvasjs-chart-canvas') as HTMLCanvasElement;
			this.canvasData.emit(canvas);
		}

		if (changes?.isLoading?.currentValue === true) {
			await sleep(2000);
			this.isLoading = false;
			this.endLoading.emit();
		}
	}

	async chartRender() {
		if (!this.chart) {
			return;
		}

		await nextTick();
		this.chart.render();
		await sleep(200);
		this.chart.render();
	}

	getItemTimeOfLegend(item: IChartPoint) {
		return time.msToTime(item.absoluteDuration);
	}

	async clickItemOfLegend(index: number) {
		const options = this.chart.options.data[0];
		const item = options.dataPoints[index];

		const isAlreadySelected = item.exploded;

		options.dataPoints.forEach(i => i.exploded = false);

		if (!isAlreadySelected) {
			item.exploded = true;
		}

		await nextTick();
		this.chart.render();

		this.onSelect.emit(this.dataPoints[index]);
	}

	async showLegend() {
		this.isLoading = true;

		this.isVisibleLegend = !this.isVisibleLegend;

		await nextTick();
		this.buildPie(!this.isVisibleLegend, true);

		await sleep(300);
		this.chart.render();

		this.isLoading = false;
	}

	private sortDataPoints() {
		this.dataPoints = this.dataPoints.sort(
			(i1, i2) =>
				i1.absoluteDuration > i2.absoluteDuration
					? -1
					: 1,
		);
	}

	private buildPie(isUseNeedle = true, isBigFontSize = false) {
		if (!document.querySelector(`#${this.pieName}`)) {
			return;
		}

		if (this.chart) {
			this.chart.destroy();
		}

		this.sortDataPoints();

		const onSelect = this.onSelect;

		const textColor = getComputedStyle(document.body)
			.getPropertyValue('--text-light');

		const isWidthCanvasBig = document.querySelector(
			'.pie-canvas',
		).clientWidth > 768 || isBigFontSize;

		const configData = {
			type: 'doughnut',
			startAngle: 180,
			innerRadius: '40%',
			yValueFormatString: '###0.0"%"',
			indexLabel: '{label} - {y}',
			indexLabelFontColor: '#4a72d7',
			indexLabelFontSize: isWidthCanvasBig
				? 20
				: 12,
			click: (e) => {
				onSelect.emit(e.dataPoint);

				const dataSeries = e.dataSeries;
				const dataPointIndex = e.dataPointIndex;

				this._eventBus.emit({
					name: this.idScrollOfLegend,
					value: dataPointIndex,
				});

				for (let i = 0; i < dataSeries.dataPoints.length; i++) {
					if (i === dataPointIndex) {
						dataSeries.dataPoints[i].exploded = true;
					} else {
						dataSeries.dataPoints[i].exploded = false;
					}
				}
			},
			dataPoints: this.dataPoints,
		};

		if (!isUseNeedle) {
			// @ts-ignore
			configData.indexLabelFontColor = 'rgba(74,114,215,0)';
			// @ts-ignore
			configData.indexLabelLineThickness = 0;
		}

		const config = {
			type: 'doughnut',
			backgroundColor: 'rgba(37,43,46,0)',
			exportEnabled: true,
			title: {
				text: this.titleText,
				fontSize: 24,
				fontFamily: 'arial',
				fontWeight: 'normal',
				fontColor: textColor,
			},
			subtitles: [{
				text: this.subtitlesText,
				fontSize: isWidthCanvasBig
					? 22
					: 16,
				fontFamily: 'arial',
				fontWeight: 'normal',
				fontColor: textColor,
			}],

			toolTip: {
				cornerRadius: 5,
				backgroundColor: 'rgba(76,76,76,0.9)',
				fontColor: textColor,
				fontSize: isWidthCanvasBig
					? 22
					: 15
				,
				borderThickness: 3,
				contentFormatter: function (e) {
					return '<div style="color:#d7d6d6">' +
						'<span style="color:#fed42a; text-shadow: 1px 1px 2px black, 0 0 1em #ffffff">' +
						'<b style="color:' + e.entries[0].dataPoint.color + '">' +
						e.entries[0].dataPoint.label +
						'</b> ' +
						e.entries[0].dataPoint.y.toFixed(1) + '%' +
						(
							e.entries[0].dataPoint.count
								? ('<div>Колличество ' + e.entries[0].dataPoint.count + '</div>')
								: '') +
						(e.entries[0].dataPoint.absoluteDuration
							? '<div>' +
							time.msToTime(e.entries[0].dataPoint.absoluteDuration) +
							'</div>'
							: '') +
						'</span>' +
						'</div>';
				},
			},

			axisX: {},
			axisY: {},
			dataPointWidth: 50,
			labels: false,
			options: {
				responsive: true,
				animation: {
					animateScale: true,
					animateRotate: true,
				},
				plugins: {
					labels: {
						render: args => {
							if (args.percentage < 20) {
								return '';
							}
							return args.percentage + '%dd';
						},
						fontColor: '#000000',
						precision: 2,
					},
				},
			},
			data: [configData],
		};


		this.chart = new CanvasJS.Chart(this.pieName, config);
	}
}
