import {
	ComponentRef,
	Directive,
	ElementRef,
	HostListener,
	OnInit,
	Renderer2,
	ViewContainerRef
} from '@angular/core';

import { ScrollUpComponent } from './scroll-up.component';

@Directive({
	selector: '[appScrollUp]',
})
export class ScrollUpDirective implements OnInit {
	scroll: ComponentRef<ScrollUpComponent>;
	list: HTMLElement;
	firstChildElement: HTMLElement;

	constructor(
		private renderer2: Renderer2,
		private viewContainer: ViewContainerRef,
		private elementRef: ElementRef
	) {
		this.scroll = this.viewContainer.createComponent(ScrollUpComponent);
	}

	ngOnInit() {
		this.firstChildElement =
			(this.elementRef.nativeElement as HTMLElement).children[0] as HTMLElement;

		this.scroll.instance.getSubscriptionClickHandler().subscribe(() => {
			this.firstChildElement.parentElement.scroll({
				top: 0,
			});
			this.scroll.instance.isVisible = false;
		});

		this.list = (this.elementRef.nativeElement as HTMLElement);

		this.renderer2.setStyle(this.elementRef.nativeElement, 'scroll-behavior', 'smooth');
	}

	@HostListener('wheel', ['$event']) handlerWheel(event: WheelEvent): void {
		this.scroll.instance.isVisible =
			this.firstChildElement.parentElement.scrollTop > 200;
	}
}
