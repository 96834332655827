import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PieCanvasComponent} from '@shared/components/charts/modules/pie-canvas/pie-canvas.component';
import {ScrollModule} from '@shared/ui-components/scroll/scroll.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ButtonComponent} from '@shared/ui-components/button/button.component';


@NgModule({
	declarations: [PieCanvasComponent],
	imports: [
		CommonModule,
		ScrollModule,
		MatButtonModule,
		MatIconModule,
		ButtonComponent,
	],
	exports: [PieCanvasComponent],
})
export class PieCanvasModule {
}
