import {Component} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Component({
	template: `
		<button
			mat-icon-button
			[class.visible]="isVisible"
			(click)="scrollUp()"
		>
			<mat-icon svgIcon="arrow-back"></mat-icon>
		</button>
	`,
	styles: [`
            button {
				background: var(--bg-hover-primary);
				color: var(--text-middle);
				position: absolute;
				z-index: 100;
				bottom: 20px;
				right: 20px;
				opacity: 0;
				transition: all 0.15s ease-in-out;
				transform: scale(0.2);
				box-shadow: var(--shadow-1);
			}

			button:hover {
				background: var(--bg-hover-primary);
				color: var(--text-light);
			}

			mat-icon {
				transform: rotate(90deg);
			}

			.visible {
				opacity: 1;
				transform: translateY(-20px) scale(1);
			}
    `]
})
export class ScrollUpComponent {
	public isVisible: boolean;
	private clickOnScrollUp$: Subject<void>;

	constructor() {
		this.isVisible = false;
	}

	public scrollUp(): void {
		this.clickOnScrollUp$.next(null);
	}

	public getSubscriptionClickHandler(): Observable<void> {
		this.clickOnScrollUp$ = new Subject<void>();
		return this.clickOnScrollUp$.asObservable();
	}
}
