import {Component, ContentChild, Input, ViewChild} from '@angular/core';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {ScrollItemDirective} from '@shared/ui-components/scroll/directives/scroll.directive';

@Component({
	selector: 'app-scroll',
	templateUrl: './scroll.component.html',
	styleUrls: ['./scroll.component.scss']
})
export class ScrollComponent {
	@ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
	@ContentChild(ScrollItemDirective) viewItem!: ScrollItemDirective;

	@Input() data: Array<Object> = [];
	@Input() id = 'scroll';
	@Input() itemSize: string = '15';
	@Input() usePaddingBottom = true;
}
