<div
	*ngIf="pieName"
	class="pie-canvas__wrapper"
>
	<div
		class="pie-canvas__container"
		[class.pie-canvas_half-max-width]="isVisibleLegend"
	>
		<div
			id="{{pieName}}"
			class="pie-canvas"
		></div>

		<div
			*ngIf="isLoading"
			class="pie-canvas__loader g-loader"
		>
			<span></span>
		</div>
	</div>

	<button
		mat-mini-fab
		class="pie-canvas__float-button"
		(click)="showLegend()"
	>
		<mat-icon
			[svgIcon]="isVisibleLegend ? 'close' : 'list-task'"
		></mat-icon>
	</button>

	<div
		*ngIf="isVisibleLegend && chart"
		class="legend"
	>
		<app-scroll
			[id]="idScrollOfLegend"
			[data]="dataPoints"
		>
			<div
				*scrollItem="let item, let i = index"
				class="legend__container"
				[class.legend__container_selected]="item.exploded"
				(click)="clickItemOfLegend(i)"
			>
				<div
					[style.background-color]="item.color"
					class="legend__indicator"
				></div>
				<div
					class="legend__item font-size-16"
				>
					<div class="g-line-nowrap" style="font-size: 16px;">
						<span
							class="legend__name"
						>
							{{ item.label }}</span>
						<span class="legend__percent">{{ item.y | number: '1.2-2' }}%</span>
					</div>

					<span class="legend__description">
						Количество: {{ item.count }}
					</span>
					<span *ngIf="!!item.absoluteDuration" class="legend__description">
						Время: {{ getItemTimeOfLegend(item) }}
					</span>
				</div>
			</div>
		</app-scroll>
	</div>
</div>

