<div class="g-relative-w100-h100">
	<ng-content select="[header]"></ng-content>

	<cdk-virtual-scroll-viewport
		[class.padding-bottom-0]="!usePaddingBottom"
		[itemSize]="itemSize"
	>
		<div *cdkVirtualFor="let item of data; let i = index">
			<ng-container
				*ngTemplateOutlet="viewItem.templateRef; context: { $implicit: item, index: i}"
			></ng-container>
		</div>
	</cdk-virtual-scroll-viewport>

	<ng-content select="[footer]"></ng-content>

	<app-scroll-to-top
		[id]="id"
		[cdkVirtualScrollViewport]="viewPort"
		class="g-button-scroll-to-top"
	></app-scroll-to-top>
</div>
